import React from "react";
import { useState } from "react";
import axios from "axios";
import { ENDPOINT } from "../../utils/ENDPOINT";

type ModalProps = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  message_id: number;
}


const FeedbackModal = ({ setShowModal, message_id }: ModalProps) => {
  const [text, setText] = useState("");

  const handleClick = async () => {
    try {
      console.log("MessageId: ", message_id);
      let res = await axios.post(ENDPOINT + "/feedback", {
        message_id: message_id,
        is_thumbs_up: false,
        feedback: text
      }, { withCredentials: true });

      console.log(res.data);

      setShowModal(false);
    } catch (error) {
      setShowModal(false);
      console.log(error);
    }

  };

  return (
    <div id="default-modal" tabIndex={-1} aria-hidden="true" className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full z-50 bg-gray-200 bg-opacity-70">
      <div className="relative p-4 w-full max-w-lg max-h-full mx-auto mt-20">
        <div className="relative bg-white rounded-lg shadow">

          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            onClick={() => setShowModal(false)}
          >
            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>

          <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-mdmx-auto">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                Thanks!
              </h1>
              <div className="space-y-4 md:space-y-6">

                <label className="block mb-2 text-sm font-medium text-gray-900">Please use this form to share any general thoughts or suggestions you have about your experience with Complizen.</label>
                <label className="block mb-2 text-sm font-medium text-gray-900">If you’d like to provide feedback on a specific response, click the thumbs-down icon within the chat to elaborate further.</label>

                <textarea value={text} onChange={e => setText(e.target.value)} id="message" rows={4} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" placeholder="Write your thoughts here..."></textarea>

                <button onClick={() => handleClick()} type="submit" className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Send Feedback</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackModal;
