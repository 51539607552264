import React from "react";
import { useState } from "react";


type PromptProps = {
  messages: ChatMessageProps[];
  setMessages: React.Dispatch<React.SetStateAction<ChatMessageProps[]>>;
  handleSendChat: any;
  isStreaming: boolean;
}

const Prompt = ({ messages, setMessages, handleSendChat, isStreaming }: PromptProps) => {
  const [text, setText] = useState("");

  const handleClick = async (e: any) => {
    e.preventDefault();
    if (text === "") return;

    try {
      handleSendChat(text);
    } catch (error) {
      console.log(error);
    }

    setText("");
  };

  const handleKeyDown = (e: any) => {
    if (isStreaming) return;

    if (e.keyCode == 13 && e.shiftKey == false) {
      console.log("Event shift", e.shiftKey);
      e.preventDefault();
      try {
        handleClick(e);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="relative flex max-h-60 w-full grow flex-col overflow-hidden bg-background px-8 sm:rounded-md  sm:px-12">
      <div className="flex w-full flex-col gap-1.5 rounded-[26px] p-1.5 transition-colors bg-[#f4f4f4]">
        <form className="flex items-end gap-1.5 md:gap-2" onSubmit={e => handleClick(e)}>
          <div className="flex min-w-0 flex-1 flex-col pl-4">
            <textarea
              tabIndex={0}
              value={text}
              onChange={(e) => setText(e.target.value)}
              onKeyDown={handleKeyDown}
              rows={1} placeholder="Message ComplizenAI" className="flex min-h-[30px] w-full resize-none rounded-md bg-transparent px-3 py-2 text-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50" >
            </textarea>
          </div>
          <button type="submit" data-testid="fruitjuice-send-button" disabled={isStreaming} className="mb-1 me-1 flex h-8 w-8 items-center justify-center rounded-full bg-primary text-white transition-colors hover:opacity-70 focus-visible:outline-none focus-visible:outline-black disabled:bg-[#D7D7D7] disabled:text-[#f4f4f4] disabled:hover:opacity-100 "><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32" className="icon-2xl"><path fill="currentColor" fill-rule="evenodd" d="M15.192 8.906a1.143 1.143 0 0 1 1.616 0l5.143 5.143a1.143 1.143 0 0 1-1.616 1.616l-3.192-3.192v9.813a1.143 1.143 0 0 1-2.286 0v-9.813l-3.192 3.192a1.143 1.143 0 1 1-1.616-1.616z" clip-rule="evenodd"></path></svg></button>
        </form>
      </div>
    </div>
  );
};

export default Prompt;
