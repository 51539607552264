import React from "react";
import hanburgerLogo from "../../utils/menu.png";
import { useState } from "react";

import Sidebar from "../Sidebar";


type HamburgerMenuProps = {
  chatCount: number,
  setConversationId: any,
  conversationId: number,
  setIsFirst: any
}

const HamburgerMenu = ({chatCount, setConversationId, conversationId, setIsFirst}:HamburgerMenuProps) => {
  const [visible, setVisible] = useState(false);

  return (
    <div className="mt-1 block tablet:hidden cursor-pointer">
      <div onClick={() => setVisible(!visible)} className="w-5 ml-5 mt-5">
        <img src={hanburgerLogo} alt="Menu" />
      </div>
      {visible && (
        <div
          className="w-screen h-full top-0 left-0 z-10 bg-black bg-opacity-25 absolute"
          onClick={() => setVisible(false)}
        >
          <div className="" onClick={(e) => e.stopPropagation()}>
            <Sidebar
              chatCount={chatCount}
              setConversationId={setConversationId}
              conversationId={conversationId}
              setIsFirst={setIsFirst}
              hamburger
              handleCloseClick={() => setVisible(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default HamburgerMenu;
