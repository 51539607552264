import SourceSelectButton from "../SourceSelectButton";
import source_icon from "../../utils/source_icon.svg";

type SourceGroupProp = {
  sources: any,
  setCurrentSource: any,
  setPageNumber: boolean,
}


const SourceGroup = ({ sources, setCurrentSource, setPageNumber }: SourceGroupProp) => {
  return (
    <div className="w-full">
      <div className="w-auto mx-20 pt-5">
        <div className="ml-5 flex">
          <img src={source_icon} />
          Sources
        </div>
        <div className="overflow-x-scroll p-4 flex flex-1 flex-row">
          {
            sources.map((e: any, i: number) => {
              return (
                <SourceSelectButton key={i} sourceName={e.source} handleSourceSelect={setCurrentSource} page={parseInt(e.page)} setPageNumber={setPageNumber} />
              );
            })
          }
        </div>
      </div>
    </div>

  );
};

export default SourceGroup;
