import React, { useState } from "react";

type TermsAndConditionsModalProps = {
  setShowTCModal: React.Dispatch<React.SetStateAction<boolean>>;
  onAccept: () => void;
};

const UserAgreementModal = ({ setShowTCModal, onAccept }: TermsAndConditionsModalProps) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleAccept = () => {
    if (isChecked) {
      onAccept();
      setShowTCModal(false);
    }
  };

  return (
    <div className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full bg-gray-200 bg-opacity-70">
      <div className="relative p-4 w-full max-w-2xl max-h-full mx-auto mt-20">
        <div className="relative bg-white rounded-lg shadow">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h2 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
              User Agreement for Complizen Beta Platform
            </h2>
            <div className="h-64 overflow-y-auto border p-4 rounded-lg">
              <p>By clicking "I Agree" below, you, the user ("User"), agree to participate in the beta testing program ("Program") for our AI compliance platform ("Platform") under the following terms and conditions. This Agreement is between Neuster Health, LLC, a Florida-based corporation ("Company"), and you, the User.</p>
              <br />
              <p>This beta testing program is designed for Complizen, an AI platform aimed at assisting medical device manufacturers with FDA regulations. Participants will interact with Complizen by asking questions and receiving guidance along with relevant FDA documents. Feedback on the platform's performance, including accuracy of information provided, is a critical component of this program.</p>

              <h2 className="text-md font-bold mt-4 mb-2">1. Confidentiality</h2>
              <h3 className="text-md font-medium">1.1. Non-Disclosure Agreement (NDA)</h3>
              <p>You agree not to share any proprietary information about the Platform or the beta testing process.</p>

              <h3 className="text-md font-medium">1.2. Protection of Proprietary Data</h3>
              <p>We'll protect any trade secrets or confidential information you may access. Your confidentiality obligations last for 5 years after this Agreement ends.</p>

              <h2 className="text-md font-bold mt-4 mb-2">2. Non-Compete</h2>
              <h3 className="text-md font-medium">2.1. Restrictions on Competing Products</h3>
              <p>You agree not to use insights from the beta testing to develop competing products for 48 months post-testing, within the United States.</p>

              <h2 className="text-md font-bold mt-4 mb-2">3. Data Usage Rights</h2>
              <h3 className="text-md font-medium">3.1. User Data</h3>
              <p>You own any proprietary data you input during testing. You grant us a non-exclusive, royalty-free, worldwide license to use this data to improve the Platform indefinitely. We will notify you of any third-party access to your data.</p>

              <h3 className="text-md font-medium">3.2. Data Privacy and Handling</h3>
              <p>We comply with the Florida Information Protection Act and other applicable data protection laws in handling your personal data.</p>

              <h2 className="text-md font-bold mt-4 mb-2">4. Ownership of Output Data</h2>
              <h3 className="text-md font-medium">4.1. User-Generated Content</h3>
              <p>You own the content you create. We have a non-exclusive, royalty-free, worldwide license to use the output data for internal purposes, indefinitely.</p>

              <h3 className="text-md font-medium">4.2. Protection of Platform Structure</h3>
              <p>We reserve the right to protect the format and structure of the Platform's output.</p>

              <h2 className="text-md font-bold mt-4 mb-2">5. Liability and Warranties</h2>
              <h3 className="text-md font-medium">5.1. "As Is" Disclaimer</h3>
              <p>The Platform is provided "as is," without warranties. While we're not liable for its accuracy or reliability, we will make best efforts to investigate and correct reported inaccuracies. You use the Platform at your own risk.</p>

              <h3 className="text-md font-medium">5.2. No Liability for Information Provided</h3>
              <p>We're not liable for errors or inaccuracies in the Platform's information. You agree not to hold us responsible for any direct or indirect damages from using the Platform.</p>

              <h3 className="text-md font-medium">5.3. Limitation of Liability</h3>
              <p>Our liability is limited under applicable law. You agree to indemnify us against claims related to your use of the Platform.</p>

              <h2 className="text-md font-bold mt-4 mb-2">6. User Feedback</h2>
              <h3 className="text-md font-medium">6.1. Feedback Meetings</h3>
              <p>You agree to participate in feedback sessions to help us improve the Platform. You grant us a perpetual, irrevocable license to use your feedback.</p>

              <h2 className="text-md font-bold mt-4 mb-2">7. Compliance and Legal Rights</h2>
              <h3 className="text-md font-medium">7.1. Compliance with Laws</h3>
              <p>You agree to comply with all relevant laws, including those governing medical devices and data privacy.</p>

              <h3 className="text-md font-medium">7.2. Export Control</h3>
              <p>You won't export the Platform against applicable laws.</p>

              <h3 className="text-md font-medium">7.3. Amendment Procedure</h3>
              <p>We may amend this Agreement with notice. Amendments are effective upon your continued use of the Platform.</p>

              <h2 className="text-md font-bold mt-4 mb-2">8. Termination</h2>
              <h3 className="text-md font-medium">8.1. By Company</h3>
              <p>We can end this Agreement at any time.</p>

              <h3 className="text-md font-medium">8.2. By User</h3>
              <p>You can end this Agreement at any time, following its terms.</p>

              <h3 className="text-md font-medium">8.3. Effects of Termination</h3>
              <p>Upon ending, you'll stop using the Platform and destroy all related materials.</p>

              <h2 className="text-md font-bold mt-4 mb-2">9. Miscellaneous</h2>
              <h3 className="text-md font-medium">9.1. Governing Law</h3>
              <p>This Agreement is governed by Florida law.</p>

              <h3 className="text-md font-medium">9.2. Dispute Resolution</h3>
              <p>Disputes will be resolved through arbitration in Florida.</p>

              <h3 className="text-md font-medium">9.3. Assignment</h3>
              <p>You can't transfer this Agreement without our consent.</p>

              <h3 className="text-md font-medium">9.4. Entire Agreement</h3>
              <p>This is our entire understanding about the beta testing.</p>

              <br />
              <p>By clicking "I Agree," you confirm your acceptance of these terms and your desire to participate in the beta testing program.</p>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                id="accept-tc"
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
              />
              <label htmlFor="accept-tc" className="ml-2 text-sm font-medium text-gray-900">
                I Agree
              </label>
            </div>
            <button
              onClick={handleAccept}
              disabled={!isChecked}
              className={`w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ${!isChecked ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              Accept and Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAgreementModal;
