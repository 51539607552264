import React from "react";
import { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import HamburgerMenu from "../../components/HamburgerMenu";
import ChatBlock from "../../components/ChatBlock";
import LogInModal from "../../components/LogInModal";
import { ENDPOINT } from "../../utils/ENDPOINT";
import axios from "axios";
import PDFViewer from "../../components/PDFViewer";
import { get } from "http";
import { useNavigate } from 'react-router-dom';

const getConversationIdFromLocal = () => {
  return localStorage.getItem('conversationId') ? parseInt(localStorage.getItem('conversationId')!) : 0;
};

const ChatPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [conversationId, setConversationId] = useState(0);
  const [isFirst, setIsFirst] = useState(true);
  const [chatCount, setChatCount] = useState(0);
  const [pdfUrl, setPdfUrl] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const navigate = useNavigate();

  const checkAuth = async () => {
    console.log("Checking auth");
    try {
      let res = await axios.post(ENDPOINT + "/auth", {}, { withCredentials: true });

      if (res.data.detail === "invalid token") {
        setShowModal(true);
      } else {
        setShowModal(false);
        setConversationId(getConversationIdFromLocal());
      }

      let profileRequest = await axios.get(ENDPOINT + "/profile", { withCredentials: true });
      let profileData = profileRequest.data;
      console.log("Profile complete: ", profileData);

      if (profileData === null || profileData === undefined) {
        navigate("/profile");
      }

    } catch (error) {
      setShowModal(true);
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);

  return (
    <div className="flex flex-row bg-[#F6F5FA]">
      {
        showModal && <LogInModal setShowModal={setShowModal} setChatCount={setChatCount} />
      }
      <div className="hidden tablet:block h-screen">
        <Sidebar chatCount={chatCount} setConversationId={setConversationId} setIsFirst={setIsFirst} conversationId={conversationId} setPdfUrl={setPdfUrl} />
      </div>
      <div className="tablet:hidden">
        <HamburgerMenu chatCount={chatCount} setConversationId={setConversationId} conversationId={conversationId} setIsFirst={setIsFirst} />
      </div>
      <div className="mx-auto grid grid-cols-2 py-3 gap-5 px-5 w-full" ml-5>
        <ChatBlock
          conversationId={conversationId}
          setConversationId={setConversationId}
          isFirst={isFirst}
          setIsFirst={setIsFirst}
          setChatCount={setChatCount}
          chatCount={chatCount}
          setPdfUrl={setPdfUrl}
          setPageNumber={setPageNumber}
          pdfUrl={pdfUrl}
        />

        {
          pdfUrl && <PDFViewer pdfUrl={pdfUrl} pageNumber={pageNumber} />
        }

      </div>




      {/* <Sidebar active={1} /> */}
    </div>
  );
};

export default ChatPage;
