import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { ENDPOINT } from "../../utils/ENDPOINT";


const AddUserTab = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const handleClick = async () => {


    const res = await axios.post(ENDPOINT + "/new_user", {
      name: userName,
      email: "",
      password: password,
    }, {
      withCredentials: true
    });

    setUserName("");
    setPassword("");

    console.log(res.data);
  };

  return (
    <div>
      <div className="overflow-y-auto overflow-x-hidden justify-center items-center w-full md:inset-0 md:h-full">
        <div className="p-4 w-full max-w-2xl h-full md:h-auto">
          <div className="relative p-4 bg-white rounded-lg shadow border-2 sm:p-5">
            <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5">
              <h3 className="text-lg font-semibold text-gray-900">
                Add User
              </h3>
            </div>
            <div>
              <div className="grid gap-4 mb-4 sm:grid-cols-2">
                <div>
                  <div className="block mb-2 text-sm font-medium text-gray-900 ">Name</div>
                  <input value={userName} onChange={e => setUserName(e.target.value)} type="text" name="username" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Type a new username" />
                </div>
                <div>
                  <div className="block mb-2 text-sm font-medium text-gray-900">Password</div>
                  <input value={password} onChange={e => setPassword(e.target.value)} type="text" name="brand" id="brand" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="New Password" />
                </div>
              </div>
              <button onClick={() => handleClick()} className="text-white inline-flex items-center w-full bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                Add new user
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUserTab;
