import React from "react";
import { useState } from "react";
import LogInModal from "../../components/LogInModal";
import HeaderTab from "../../components/HeaderTab";
import AddUserTab from "../../components/AddUserTab";

const AdminPage = () => {
  const [showModal, setShowModal] = useState(true);
  const [activeTab, setActiveTab] = useState("profile");
  return (
    <div className="flex relative w-screen ">
      {
        showModal && <LogInModal setShowModal={setShowModal} />
      }
      <div className="w-full flex items-center">
        <div className="flex flex-col w-full items-center">
          <div className="mb-4 border-b border-gray-200  mx-auto">
            <ul className="flex flex-wrap flex-row gap-20 -mb-px text-sm font-medium text-center" id="default-styled-tab" data-tabs-toggle="#default-styled-tab-content" data-tabs-active-classNamees="text-purple-600 hover:text-purple-600 border-purple-600" data-tabs-inactive-classNamees="text-gray-500 hover:text-gray-600  border-gray-100 hover:border-gray-300" role="tablist">
              <HeaderTab activeTab={activeTab} setActiveTab={setActiveTab} text="Add User and Admin" tabValue="profile" />
              <HeaderTab activeTab={activeTab} setActiveTab={setActiveTab} text="View Feedbacks" tabValue="feedback" />
              <HeaderTab activeTab={activeTab} setActiveTab={setActiveTab} text="Dashboard" tabValue="dashboard" />
              <HeaderTab activeTab={activeTab} setActiveTab={setActiveTab} text="Contacts" tabValue="users" />
            </ul>
          </div>
          <div className="max-w-screen-lg">
            {
              activeTab === "profile" &&
              <AddUserTab />
            }
            {
              activeTab === "feedback" &&
              <div className="p-4 rounded-lg bg-gray-50" id="styled-dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                <p className="text-sm text-gray-500">This is some placeholder content the <strong className="font-medium text-gray-800">Dashboard tab's associated content</strong>. Clicking another tab will toggle the visibility of this one for the next. The tab JavaScript swaps classNamees to control the content visibility and styling.</p>
              </div>
            }
            {
              activeTab === "dashboard" &&
              <div className="p-4 rounded-lg bg-gray-50" id="styled-settings" role="tabpanel" aria-labelledby="settings-tab">
                <p className="text-sm text-gray-500">This is some placeholder content the <strong className="font-medium text-gray-800">Settings tab's associated content</strong>. Clicking another tab will toggle the visibility of this one for the next. The tab JavaScript swaps classNamees to control the content visibility and styling.</p>
              </div>
            }
            {
              activeTab === "users" &&
              <div className="p-4 rounded-lg bg-gray-50" id="styled-contacts" role="tabpanel" aria-labelledby="contacts-tab">
                <p className="text-sm text-gray-500 ">This is some placeholder content the <strong className="font-medium text-gray-800">Contacts tab's associated content</strong>. Clicking another tab will toggle the visibility of this one for the next. The tab JavaScript swaps classNamees to control the content visibility and styling.</p>
              </div>
            }
          </div>
        </div>

      </div>

    </div>
  );
};

export default AdminPage;
