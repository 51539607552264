type SourceSelectProps = {
  sourceName: string,
  handleSourceSelect: any,
  page: number,
  setPageNumber: any
}

const SourceSelectButton = ({ sourceName, handleSourceSelect, setPageNumber, page }: SourceSelectProps) => {
  const handleClick = () => {
    handleSourceSelect(sourceName);
    setPageNumber(page);
  };

  return (
    <button type="button" value={sourceName} onClick={() => handleClick()} className="overflow-hidden min-w-[120px] max-w-[120px] border bg-gray-100 rounded-lg border-gray-400 text-bold focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-3 py-2 text-left me-2 mb-2">
      {sourceName.slice(0, 50)}...
    </button>
  );
};

export default SourceSelectButton;
