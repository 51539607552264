import React, { useEffect } from "react";
import { useState } from "react";

import axios from "axios";
import { ENDPOINT } from "../../utils/ENDPOINT";
import { useNavigate } from 'react-router-dom';


const ERR_EMPTY_FIELD = "Please fill out all fields";
const ERR_TOO_LONG = "Please keep texts under 200 characters";
const COMPANY_DESC_MAX = 1000;
const PRODUCT_DESC_MAX = 500;

const ProfilePage = () => {
  const [errMessage, setErrMessage] = useState("");
  const [productAdded, setProductAdded] = useState(false);
  const [profile, setProfile] = useState({
    name: "",
    email: "",
    companyName: "",
    areaOfIssue: "",
    companyDescription: "",
    products: [{
      productName: "",
      productArea: "",
      productExplanation: ""
    }]
  });

  const [products, setProducts] = useState([{
    productName: "",
    productArea: "",
    productExplanation: ""
  }]);
  const navigate = useNavigate();

  const handleAddProduct = () => {
    setProducts([...products, {
      productName: "",
      productArea: "",
      productExplanation: ""
    }]);
  };

  const handleDelete = (index: number) => {
    let p = [...products];
    p.splice(index, 1);
    setProducts(p);
  };

  const updateField = (e: any, field: string) => {
    setProfile({ ...profile, [field]: e.target.value });
  };

  const updateProductField = (e: any, field: string, index: number) => {
    let p = [...products];
    p[index][field as keyof typeof p[0]] = e.target.value;
    setProducts(p);
  };

  const handleSubmit = async () => {
    let s = { ...profile };
    s.products = products;

    console.log(s);

    if (profile.name === "" || profile.email === "" || profile.companyName === "" || profile.areaOfIssue === "" || profile.companyDescription === "") {
      setErrMessage(ERR_EMPTY_FIELD);
      return;
    }

    if (profile.name.length > 200 || profile.areaOfIssue.length > 200 || profile.companyName.length > 200 || profile.email.length > 200) {
      setErrMessage(ERR_TOO_LONG);
      return;
    }

    if (profile.companyDescription.length > COMPANY_DESC_MAX) {
      setErrMessage(`Please keep company description under ${COMPANY_DESC_MAX} characters`);
      return;
    }

    for (let i = 0; i < products.length; i++) {
      let pr = products[i];
      if (pr.productName === "" || pr.productArea === "" || pr.productExplanation === "") {
        setErrMessage(ERR_EMPTY_FIELD);
        return;
      }

      if (pr.productName.length > 200 || pr.productArea.length > 200) {
        setErrMessage(ERR_TOO_LONG);
        return;
      }

      if (pr.productExplanation.length > PRODUCT_DESC_MAX) {
        setErrMessage(`Please keep product description under ${PRODUCT_DESC_MAX} characters`);
        return;
      }
    }

    setErrMessage("");

    let res = await axios.post(ENDPOINT + "/profile", { profile: s }, { withCredentials: true });

    console.log(res.data);

    navigate("/");

  };

  const getProfile = async () => {
    let res = await axios.get(ENDPOINT + "/profile", { withCredentials: true });
    let data = res.data;
    console.log("Data:  ", data);
    if (data) {
      setProfile(data.profile);
      setProducts(data.profile.products);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);


  return (
    <div className="h-screen flex flex-col">
      <div className="max-w-[800px] h-auto mx-auto mt-10 overflow-y-scroll">

        <h1 className="flex items-center text-5xl font-extrabold ">Complizen<span className="bg-blue-100 text-blue-800 text-2xl font-semibold me-2 px-2.5 py-0.5 rounded ms-2">BETA</span></h1>
        <br />
        <p className="text-lg font-normal text-gray-500 lg:text-xl ">Please fill out personal and company information to continue. This information will personalize your experience.</p>
        <div className="mt-10">
          <h4 className="text-2xl font-bold">Personal Information</h4>
          <div className="grid gap-6 mb-6 md:grid-cols-2 mt-5">
            <div>
              <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900">Name</label>
              <input value={profile.name} onChange={e => updateField(e, "name")} type="text" id="first_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="John Doe" required />
            </div>
            <div className="mb-6">
              <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">Email address</label>
              <input value={profile.email} onChange={e => updateField(e, "email")} type="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="john.doe@company.com" required />
            </div>
          </div>
        </div>

        <div className="mt-10">
          <h4 className="text-2xl font-bold">Company Information</h4>
          <div className="grid gap-6 mb-6 md:grid-cols-2 mt-5">
            <div>
              <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900">Name</label>
              <input value={profile.companyName} onChange={e => updateField(e, "companyName")} type="text" id="first_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="E.g. Complizen Ltd." required />
            </div>
            <div>
              <label htmlFor="last_name" className="block mb-2 text-sm font-medium text-gray-900">Type</label>
              <input value={profile.areaOfIssue} onChange={e => updateField(e, "areaOfIssue")} type="text" id="last_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="E.g. Manufacturer / Importer / Consultant" required />
            </div>
          </div>
          <div className="mb-6">
            <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900">Description</label>
            <textarea value={profile.companyDescription} onChange={e => updateField(e, "companyDescription")} id="message" rows={4} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" placeholder="E.g. Complizen Ltd. is a MDM based in Istanbul/Turkey, with 2 manufacturing facilities located in Mersin. &#10;Complizen has 510(k) approval for their Surgical Motor System product named Bravecto. &#10;The manifacturing facilities are already registered with FDA, and Complizen has a US Agent."></textarea>
          </div>
        </div>
        <div>
          <div className="flex flex-row">
            <h4 className="text-2xl font-bold">Product Information</h4>

            <button onClick={() => handleAddProduct()} type="button" className="text-white ml-auto bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2">Add product</button>

          </div>
          {
            products.map((product, index) => {
              return (
                <div key={index}>
                  <div className="flex flex-row">
                    <h4 className="text-lg font-bold mt-5">Product {index + 1}</h4>
                    {
                      index > 0 &&
                      <button onClick={() => handleDelete(index)} type="button" className="px-3 py-2 h-7 mt-auto ml-auto text-xs font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300">
                        Delete product
                      </button>
                    }
                  </div>

                  <div className="grid gap-6 mb-6 md:grid-cols-2 mt-2">
                    <div>
                      <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900">Brand Name</label>
                      <input value={product.productName} onChange={e => updateProductField(e, "productName", index)} type="text" id="first_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="E.g. Bravecto" required />
                    </div>
                    <div>
                      <label htmlFor="last_name" className="block mb-2 text-sm font-medium text-gray-900">Product</label>
                      <input value={product.productArea} onChange={e => updateProductField(e, "productArea", index)} type="text" id="last_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="E.g. Surgical Motor System" required />
                    </div>
                  </div>
                  <div className="mb-6">
                    <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900">Description</label>
                    <textarea value={product.productExplanation} onChange={e => updateProductField(e, "productExplanation", index)} id="message" rows={4} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" placeholder="E.g. Motorized equipment used in orthopedic surgical procedures. (100-200 characters)"></textarea>

                  </div>
                </div>
              );
            })
          }

        </div>
      </div>
      <div className="text-red-500 text-center mt-5">{errMessage}</div>
      <button onClick={() => handleSubmit()} type="submit" className="max-w-[800px] mx-auto w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Submit and Continue</button>
    </div>
  );
};

export default ProfilePage;
