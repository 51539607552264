import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { ENDPOINT } from "../../utils/ENDPOINT";
import UserAgreementModal from "../UserAgreementModal";
import { useNavigate } from 'react-router-dom';


type ModalProps = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setChatCount: any;
}

const LogInModal = ({ setShowModal, setChatCount }: ModalProps) => {
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errMessage, setErrMessage] = useState("");
  const [showTCModal, setShowTCModal] = useState(false);
  const navigate = useNavigate();


  const handleClick = async () => {
    try {
      let res = await axios.post(ENDPOINT + "/sign-in", {
        email: userEmail,
        password: password,
      }, { withCredentials: true });

      let res_terms = await axios.get(ENDPOINT + `/terms`, { withCredentials: true });

      if (res_terms.data == null || res_terms.data == undefined) {
        setShowTCModal(true);
      } else {
        completeLogin();
      }
    } catch (error) {
      setShowModal(true);
      console.log("Error: ", error);
      setErrMessage("Invalid email or password");
      setUserEmail("");
      setPassword("");
    }
  };

  const completeLogin = async () => {
    let profileRequest = await axios.get(ENDPOINT + "/profile", { withCredentials: true });
    let profileData = profileRequest.data;
    console.log("Profile complete: ", profileData);

    if (profileData === null || profileData === undefined) {
      navigate("/profile");
    }

    setShowModal(false);
    setChatCount(1);
  };

  const handleAcceptTC = async () => {
    try {
      let payload = {
        date_user_agreed_on: new Date().toDateString(),
        description: "agreement file name: User Agreement for Complizen Beta Platform"
      };
      await axios.post(ENDPOINT + "/terms", payload, { withCredentials: true });
      // console.log("ACCEPTED User Agreement!!!");
      completeLogin();
    } catch (error) {
      console.log("Error accepting User Agreement: ", error);
      setErrMessage("Error accepting User Agreement!");
    }
  };

  useEffect(() => {
    localStorage.removeItem('conversationId');
  }, []);

  if (showTCModal) {
    return <UserAgreementModal setShowTCModal={setShowTCModal} onAccept={handleAcceptTC} />;
  }

  return (
    <div id="default-modal" tabIndex={-1} aria-hidden="true" className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full z-50 bg-gray-200 bg-opacity-70">
      <div className="relative p-4 w-full max-w-lg max-h-full mx-auto mt-20">
        <div className="relative bg-white rounded-lg shadow">
          <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-mdmx-auto">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                Sign in to your account
              </h1>
              <p className="text-sm font-light text-gray-500">
                Only available for beta users. Please contact info@complizen.ai for access.
              </p>
              <div className="space-y-4 md:space-y-6">
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900 text-left">Your email</label>
                  <input value={userEmail} onChange={e => setUserEmail(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5" placeholder="username" />
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900 text-left">Password</label>
                  <input value={password} onChange={e => setPassword(e.target.value)} type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5" />
                </div>
                <button onClick={() => handleClick()} type="submit" className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Sign in</button>
                <p className="text-sm font-light text-gray-500">
                  Don’t have an access yet? <a href="#" className="font-medium text-blue-600 hover:underline" onClick={(e) => { window.location.href = 'mailto:info@complizen.ai'; }}>Contact Us</a>
                </p>
                <p className="text-sm font-light text-red-500 text-center">
                  {errMessage}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogInModal;
