type ModalProps = {
    setActiveTab: React.Dispatch<React.SetStateAction<string>>;
    activeTab: string;
    text: string;
    tabValue: string;
}

const HeaderTab = ({setActiveTab, activeTab, text, tabValue}: ModalProps) => {
  return(
    <div>
      <li className="me-2" role="presentation">
        <button onClick={() => setActiveTab(tabValue)} className={"inline-block p-4 border-b-2 rounded-t-lg" + (activeTab === tabValue ? " text-blue-700":"")}>
          {text}
        </button>
      </li>
    </div>
  );
};

export default HeaderTab;
