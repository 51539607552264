import React, { useEffect, useRef } from "react";

type DropdownMenuProps = {
  isOpen: boolean;
  onClose: () => void;
  position: "above" | "below";
  children: React.ReactNode;
};

const DropdownMenu = ({ isOpen, onClose, position, children }: DropdownMenuProps) => {
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isOpen) return;

    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  const positionClasses = position === "above" ? "bottom-full mb-2" : "top-full mt-2";

  return (
    <div
      ref={menuRef}
      className={`absolute ${positionClasses} right-0 w-32 bg-white bg-opacity-90 backdrop-blur-sm rounded-md shadow-lg z-20`}
    >
      {children}
    </div>
  );
};

export default DropdownMenu;
