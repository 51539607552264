import React, { useEffect, useState, useRef } from "react";
import SidebarItem from "../SidebarItem";
import FeedbackModal from "../FeedbackModal";
import ConfirmationModal from "../ConfirmationModal";
import DropdownMenu from "../DropdownMenu";
import logo from "../../utils/complizen_logo.png";
import facility_icon from "../../utils/facility_icon.png";
import product_icon from "../../utils/product_icon.png";
import logout_icon from "../../utils/logout.svg";
import profile_icon from "../../utils/user-profile.png";
import feedback_icon from "../../utils/feedback.svg";
import options_icon from "../../utils/options_icon.svg";
import rename_icon from "../../utils/rename_icon.svg";
import delete_icon from "../../utils/delete_icon.svg";
import pin_icon from "../../utils/pin_icon.svg";
import unpin_icon from "../../utils/unpin_icon.svg";
import axios from "axios";
import { ENDPOINT } from "../../utils/ENDPOINT";
import { Link } from "react-router-dom";

type SidebarProps = {
  setConversationId: any;
  chatCount: number;
  setIsFirst: any;
  conversationId: number;
  hamburger?: boolean;
  handleCloseClick?: any;
  setPdfUrl?: any;
};

type Conversation = {
  id: number;
  user_id: number;
  title: string;
  is_pinned: boolean;
  created_at: string;
  updated_at: string;
};

const Sidebar = ({
  chatCount,
  setConversationId,
  conversationId,
  setIsFirst,
  hamburger = false,
  handleCloseClick,
  setPdfUrl,
}: SidebarProps) => {
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [active, setActive] = useState(0);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [renamingConversationId, setRenamingConversationId] = useState<number | null>(null);
  const [newTitle, setNewTitle] = useState<string>("");
  const [dropdownOpenId, setDropdownOpenId] = useState<number | null>(null);
  const [dropdownPosition, setDropdownPosition] = useState<{ [key: number]: 'above' | 'below' }>({});
  const [deleteConfirmation, setDeleteConfirmation] = useState<{ id: number; title: string } | null>(null);

  // Refs to hold the 3-dot icons for positioning
  const optionsRefs = useRef<{ [key: number]: HTMLImageElement | null }>({});
  // Ref to the scrollable container
  const scrollableRef = useRef<HTMLDivElement | null>(null);

  const fetchConversations = async () => {
    try {
      const res = await axios.get(`${ENDPOINT}/user_conversations`, { withCredentials: true });
      setConversations(res.data);
    } catch (error) {
      console.log("Error fetching conversations: ", error);
    }
  };

  useEffect(() => {
    fetchConversations();
  }, [chatCount]);

  const handleLogOut = async () => {
    try {
      const res = await axios.post(`${ENDPOINT}/log-out`, {}, { withCredentials: true });
      console.log("Log out response: ", res.data);
      window.location.reload();
    } catch (error) {
      console.log("Error logging out: ", error);
    }
  };

  const setNewChatActive = (i: number) => {
    setActive(i);
    if (setPdfUrl) {
      setPdfUrl("");
    }
  };

  const handleDelete = async (id: number) => {
    try {
      await axios.delete(`${ENDPOINT}/conversation/${id}`, { withCredentials: true });
      // Refresh the conversations list after deletion
      fetchConversations();
      setDeleteConfirmation(null);
    } catch (error) {
      console.log("Error deleting conversation: ", error);
    }
  };

  const handleRename = (id: number, currentTitle: string) => {
    setRenamingConversationId(id);
    setNewTitle(currentTitle);
    setDropdownOpenId(null);
  };

  const submitRename = async (id: number) => {
    if (newTitle.trim() === "") {
      alert("Title cannot be empty.");
      return;
    }
    try {
      await axios.put(
        `${ENDPOINT}/conversation/${id}/title`,
        { new_title: newTitle },
        { withCredentials: true }
      );
      // Refresh the conversations list after renaming
      fetchConversations();
      setRenamingConversationId(null);
      setNewTitle("");
    } catch (error) {
      console.log("Error renaming conversation: ", error);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>, id: number) => {
    if (e.key === "Enter") {
      submitRename(id);
    }
  };

  const toggleDropdown = (id: number) => {
    if (dropdownOpenId === id) {
      setDropdownOpenId(null);
      return;
    }

    // Get the position of the 3-dot icon relative to the scrollable container
    const iconElement = optionsRefs.current[id];
    const scrollableElement = scrollableRef.current;
    if (iconElement && scrollableElement) {
      const iconRect = iconElement.getBoundingClientRect();
      const scrollableRect = scrollableElement.getBoundingClientRect();
      const dropdownHeight = 280; // estimated height of dropdown
      const spaceBelow = scrollableRect.bottom - iconRect.bottom;
      const spaceAbove = iconRect.top - scrollableRect.top;

      if (spaceBelow < dropdownHeight && spaceAbove > dropdownHeight) {
        setDropdownPosition(prev => ({ ...prev, [id]: 'above' }));
      } else {
        setDropdownPosition(prev => ({ ...prev, [id]: 'below' }));
      }
    }

    setDropdownOpenId(id);
  };

  // Handler to toggle pin/unpin
  const handleTogglePin = async (id: number, currentPinned: boolean) => {
    try {
      await axios.put(
        `${ENDPOINT}/conversation/${id}/pin`,
        { is_pinned: !currentPinned },
        { withCredentials: true }
      );
      // Refresh the conversations list after toggling pin
      fetchConversations();
    } catch (error) {
      console.log("Error toggling pin: ", error);
    }
  };

  // Separate conversations into pinned and recent
  const pinnedConversations = conversations.filter(convo => convo.is_pinned);
  const recentConversations = conversations.filter(convo => !convo.is_pinned);

  return (
    <div className="flex flex-col overflow-hidden w-60 h-screen bg-white shadow-lg px-5 py-5 space-y-4" ref={scrollableRef}>
      {!hamburger ? (
        <div className="row-span-2 border-gray-300">
          <img src={logo} alt="logo" />
        </div>
      ) : (
        <div className="ml-auto mr-1" onClick={() => handleCloseClick()}>
          <button className="text-gray-500 hover:text-gray-700 focus:outline-none">
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      )}

      <div className="grow shrink basis-0 py-4 overflow-y-auto overflow-x-hidden">
        <ul className="text-sm">
          <SidebarItem
            name="New Chat"
            to="/"
            active={0 === conversationId}
            conversation_id={0}
            setConversationId={setConversationId}
            index={0}
            setActive={setNewChatActive}
          />
          <li>
            <hr className="border-t border-gray-300" />
          </li>

          {/* Pinned Conversations */}
          {pinnedConversations.length > 0 && (
            <>
              <li className="mt-2 mb-1 text-xs text-gray-500">Pinned</li>
              {pinnedConversations.map((conversation, index) => (
                <div
                  key={conversation.id}
                  className="relative group flex items-center hover:bg-secondary rounded-lg flex-row transition-colors duration-300 w-full"
                  onClick={() => {
                    setConversationId(conversation.id);
                    setIsFirst(false);
                  }}
                >
                  {renamingConversationId === conversation.id ? (
                    <input
                      type="text"
                      value={newTitle}
                      onChange={(e) => setNewTitle(e.target.value)}
                      onKeyDown={(e) => handleKeyPress(e, conversation.id)}
                      onBlur={() => setRenamingConversationId(null)}
                      autoFocus
                      className="flex-1 p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-secondary"
                    />
                  ) : (
                    <SidebarItem
                      name={conversation.title}
                      conversation_id={conversation.id}
                      setConversationId={setConversationId}
                      to={`/conversation/${conversation.id}`}
                      active={conversationId === conversation.id}
                      index={index + 1}
                      setActive={setActive}
                    />
                  )}
                  {/* Options Button */}
                  <div className="absolute right-2 top-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center">
                    <img
                      src={options_icon}
                      alt="Options"
                      className="w-4 h-4 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleDropdown(conversation.id);
                      }}
                      title="Options"
                      ref={(el) => (optionsRefs.current[conversation.id] = el)}
                    />
                    {/* Dropdown Pop-up */}
                    {dropdownOpenId === conversation.id && (
                      <DropdownMenu
                        isOpen={true}
                        onClose={() => setDropdownOpenId(null)}
                        position={dropdownPosition[conversation.id] || 'below'}
                      >
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRename(conversation.id, conversation.title);
                          }}
                          className="flex items-center w-full text-left px-2 py-1 hover:bg-secondary rounded-t-md"
                        >
                          <img src={rename_icon} alt="Rename" className="w-4 h-4 mr-2" />
                          Rename
                        </button>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleTogglePin(conversation.id, conversation.is_pinned);
                            setDropdownOpenId(null);
                          }}
                          className="flex items-center w-full text-left px-2 py-1 hover:bg-secondary"
                        >
                          <img
                            src={conversation.is_pinned ? unpin_icon : pin_icon}
                            alt={conversation.is_pinned ? "Unpin" : "Pin"}
                            className="w-4 h-4 mr-2"
                          />
                          {conversation.is_pinned ? "Unpin" : "Pin"}
                        </button>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setDeleteConfirmation({ id: conversation.id, title: conversation.title });
                            setDropdownOpenId(null);
                          }}
                          className="flex items-center w-full text-left px-2 py-1 text-red-500 hover:bg-secondary rounded-b-md"
                        >
                          <img src={delete_icon} alt="Delete" className="w-4 h-4 mr-2" />
                          Delete
                        </button>
                      </DropdownMenu>
                    )}
                  </div>
                </div>
              ))}
            </>
          )}

          {/* Recent Conversations */}
          <li className={`${pinnedConversations.length > 0 ? "mt-4 mb-1" : "mt-2 mb-1"} text-xs text-gray-500`}>
            Recents
          </li>
          {recentConversations.map((conversation, index) => (
            <div
              key={conversation.id}
              className="relative group flex items-center hover:bg-secondary rounded-lg flex-row transition-colors duration-300 w-full"
              onClick={() => {
                setConversationId(conversation.id);
                setIsFirst(false);
              }}
            >
              {renamingConversationId === conversation.id ? (
                <input
                  type="text"
                  value={newTitle}
                  onChange={(e) => setNewTitle(e.target.value)}
                  onKeyDown={(e) => handleKeyPress(e, conversation.id)}
                  onBlur={() => setRenamingConversationId(null)}
                  autoFocus
                  className="flex-1 p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-secondary"
                />
              ) : (
                <SidebarItem
                  name={conversation.title}
                  conversation_id={conversation.id}
                  setConversationId={setConversationId}
                  to={`/conversation/${conversation.id}`}
                  active={conversationId === conversation.id}
                  index={index + 1 + pinnedConversations.length}
                  setActive={setActive}
                />
              )}
              {/* Options Button */}
              <div className="absolute right-2 top-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center">
                <img
                  src={options_icon}
                  alt="Options"
                  className="w-4 h-4 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleDropdown(conversation.id);
                  }}
                  title="Options"
                  ref={(el) => (optionsRefs.current[conversation.id] = el)}
                />
                {/* Dropdown Pop-up */}
                {dropdownOpenId === conversation.id && (
                  <DropdownMenu
                    isOpen={true}
                    onClose={() => setDropdownOpenId(null)}
                    position={dropdownPosition[conversation.id] || 'below'}
                  >
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRename(conversation.id, conversation.title);
                      }}
                      className="flex items-center w-full text-left px-2 py-1 hover:bg-secondary rounded-t-md"
                    >
                      <img src={rename_icon} alt="Rename" className="w-4 h-4 mr-2" />
                      Rename
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleTogglePin(conversation.id, conversation.is_pinned);
                        setDropdownOpenId(null);
                      }}
                      className="flex items-center w-full text-left px-2 py-1 hover:bg-secondary"
                    >
                      <img
                        src={conversation.is_pinned ? unpin_icon : pin_icon}
                        alt={conversation.is_pinned ? "Unpin" : "Pin"}
                        className="w-4 h-4 mr-2"
                      />
                      {conversation.is_pinned ? "Unpin" : "Pin"}
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeleteConfirmation({ id: conversation.id, title: conversation.title });
                        setDropdownOpenId(null);
                      }}
                      className="flex items-center w-full text-left px-2 py-1 text-red-500 hover:bg-secondary rounded-b-md"
                    >
                      <img src={delete_icon} alt="Delete" className="w-4 h-4 mr-2" />
                      Delete
                    </button>
                  </DropdownMenu>
                )}
              </div>
            </div>
          ))}
        </ul>
      </div>

      <div>
        <div
          className="flex items-center pt-2 pb-2 text-gray-900 rounded-lg hover:bg-secondary cursor-pointer"
          onClick={() => setShowFeedbackModal(true)}
        >
          <img src={feedback_icon} alt="icon" className="w-5 h-5" />
          <span className="flex-1 whitespace-nowrap text-sm font-sans pl-1 pr-1">Feedback</span>
        </div>

        <div className="flex items-center pt-2 pb-2 text-gray-900 rounded-lg hover:bg-secondary cursor-pointer">
          <img src={product_icon} alt="icon" className="w-5 h-5" />
          <span className="flex-1 whitespace-nowrap text-sm font-sans pl-1 pr-1">510(k) Assistance</span>
          <span className="pb-0.5 px-1 ml-auto text-sm font-medium text-white bg-[#48afe2] rounded-full">
            soon
          </span>
        </div>

        <div className="flex items-center pt-2 pb-2 text-gray-900 rounded-lg hover:bg-secondary cursor-pointer">
          <img src={facility_icon} alt="icon" className="w-5 h-5" />
          <span className="flex-1 whitespace-nowrap text-sm font-sans pl-1 pr-1">Register a Facility</span>
          <span className="pb-0.5 px-1 ml-auto text-sm font-medium text-white bg-[#48afe2] rounded-full">
            soon
          </span>
        </div>

        <Link to="/profile" className="flex items-center pt-2 pb-2 text-gray-900 rounded-lg hover:bg-secondary cursor-pointer">
          <img src={profile_icon} alt="icon" className="w-5 h-5" />
          <span className="flex-1 whitespace-nowrap text-sm font-sans pl-1">Profile</span>
        </Link>

        <div onClick={() => handleLogOut()} className="flex items-center pt-2 pb-2 text-gray-900 rounded-lg hover:bg-secondary cursor-pointer">
          <img src={logout_icon} alt="icon" className="w-5 h-5" />
          <span className="flex-1 whitespace-nowrap text-sm font-sans pl-1">Logout</span>
        </div>
      </div>

      {/* Feedback Modal */}
      {showFeedbackModal && (
        <FeedbackModal
          setShowModal={setShowFeedbackModal}
          message_id={-5} // special message_id for general feedback
        />
      )}

      {/* Delete Confirmation Modal */}
      {deleteConfirmation && (
        <ConfirmationModal
          title="Delete Chat?"
          message={
            <span>
              Are you sure you want to delete <span className="font-bold">"{deleteConfirmation.title}"</span>?
              <br />
              <br />
              This action cannot be undone.
            </span>
          }
          onConfirm={() => handleDelete(deleteConfirmation.id)}
          onCancel={() => setDeleteConfirmation(null)}
        />
      )}
    </div>
  );
};

export default Sidebar;
