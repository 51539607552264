const TemplateChatValues = [
  {
    text: "Device Classification and Similar Devices",
    explanation: "Find the classification and review similar cleared devices.",
    value: "Help me determine the classification of my device and show me any similar devices that have been cleared?",
  },
  {
    text: "Find 510(k) Clearances",
    explanation: "Retrieve information on a specific 510(k) clearance.",
    value: "Can you help me find the 510(k) clearance information for [insert device name or product code]?"
  },
  {
    text: "eSTAR Program Overview",
    explanation: "Learn about the FDA's eSTAR program and its benefits.",
    value: "What is the eSTAR program, and how does it benefit the 510(k) submission process ?"
  },
  {
    text: "Review Adverse Event Reports",
    explanation: "Access FDA adverse event reports.",
    value: "Could you show me any FDA adverse event reports for [insert device name or product code]?"
  }
];

export default TemplateChatValues;
