import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import { Router, Routes, Route } from "react-router-dom";
import './App.css';
import ChatPage from './pages/ChatPage';
import AdminPage from './pages/AdminPage';
import ProfilePage from './pages/ProfilePage';



function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <ChatPage />,
    },
    {
      path: "/admin",
      element: <AdminPage />,
    },
    {
      path: "/profile",
      element: <ProfilePage />,
    },
  ]);
  return <RouterProvider router={router} />;
}

export default App;
