type TemplateQuestionProps = {
  handleChat: any,
  text: string,
  value: string,
  explanation: string
}

const TemplateQuestion = ({ handleChat, text, value, explanation }: TemplateQuestionProps) => {
  return (
    <div className="flex-1 border h-full border-color: rgb(101 114 129) rounded-2xl p-2 px-4 shadow-md cursor-pointer" onClick={() => handleChat(value)}>
      <div className="font-bold">{text}</div>
      <div className="text-sm text-gray-500">{explanation}</div>
    </div>
  );
};

export default TemplateQuestion;
