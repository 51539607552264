import React from "react";

interface props {
  name: string
  conversation_id: number
  setConversationId: any
  icon?: JSX.Element
  message?: string
  disabled?: boolean
  to: string
  active?: boolean
  index: number
  setActive: any
}

const SidebarItem = ({
  name,
  setConversationId,
  conversation_id,
  setActive,
  index,
  icon,
  message = "",
  disabled = false,
  to = "",
  active = false,
}: props) => {
  const handleClick = () => {
    setConversationId(conversation_id);
    setActive(index);
    localStorage.setItem('conversationId', conversation_id.toString());
  };
  return (
    <li>
      <div
        onClick={() => handleClick()}
        className={
          active
            ? "bg-secondary text-primary flex text-left p-2 py-2 rounded-lg group cursor-pointer"
            : "flex text-left p-2 py-2 text-gray-700 rounded-lg hover:bg-secondary group cursor-pointer"
        }
      >
        <span className="flex-1 s whitespace-nowrap rounded-full">{name}</span>
      </div>
    </li>
  );
};

export default SidebarItem;
