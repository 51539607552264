
type UserMessageProps = {
  text: string
}

const UserMessage = ({ text }: UserMessageProps) => {
  return (
    <div className="flex flex-grow flex-col max-w-full px-5 laptopM:px-10">
      <div className="min-h-[20px] flex flex-col items-end break-words overflow-x-auto gap-2">
        <div className="relative max-w-[70%] rounded-3xl bg-[#686DFD] px-5 py-2.5 text-left break-words">
          <p className="text-white">{text}</p>
        </div>
      </div>
    </div>
  );
};

export default UserMessage;
